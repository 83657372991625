.products {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product-cards {
  width: calc(100% / 3);
  background-color: #d3b17f;
  border-radius: 30px 0px;
}

.product-img {
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-info {
  padding: 0 20px;
}

.product-info h6 {
  font-size: 23px;
  font-weight: bold;
}

.product-cards button {
 margin-bottom: 30px;
}

.product-cards button img {
  width: 70%;
}

@media (max-width: 992px ) {
  .product-img {
    height: 300px;
  }
  .product-info h6 {
    height: 60px;
  }
  .product-info p {
    font-size: 14px;
  }
}

@media (max-width: 768px ) {
  .product-img {
    height: 230px;
  }
  .product-info h6 {
    font-size: 18px;
    height: 50px;
  }
  .product-info p {
    font-size: 10px;
  }
  .product-cards button img {
    width: 90%;
  }
}

@media (max-width: 579px ) {
  .products {
    flex-wrap: wrap;
  }
  .product-cards {
    width: 45%;
  }
  .product-img {
    height: 300px;
  }
  .product-info h6 {
    height: 40px;
  }
  .product-info p {
    font-size: 12px;
  }
  .product-cards button img {
    width: 80%;
  }
}

@media (max-width: 426px ) {
  .product-cards {
    width: 70%;
    margin: auto;
  }
  .product-info h6 {
    height: auto;
  }
}