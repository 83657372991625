#root {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

hr.divider {
  border-top: 1px solid white;
}

.banner-slider-content {
  padding: 0 50px;
}

.banner-slider-content img {
  width: 100%;
}

.appointment-info {
  position: relative;
  margin: -15% auto 0 auto;
  width: 80%;
}

.appointment-info h3 {
  font-size: 85px;
  font-weight: 700;
  color: white
}

.service-info {
  margin-top: 20px;
  padding: 50px 80px;
  background-color: #d3b17f;
}

.service-info h5 {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 50px;
}

.working-time-1 h6 {
  font-size: 30px;
  margin-bottom: 10px;
}

.working-time-1 p {
  font-size: 24px;
  margin: 0;
}

.service-info-content .buttons {
  margin-top: 20px;
}

.book-button {
  width: 100%;
  max-width: 300px;
}

.services-ratings {
  width: 60%;
  margin: 0 auto;
  padding: 100px 0;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  display: flex;
}

.services-ratings h3 {
  color: #00d3b0;
  font-size: 80px;
  font-weight: bold;
}

.services-ratings h6 {
  color: black;
  font-size: 20px;
}

.medichip {
  background-color: #00d3b0;
}

.medichip img {
  width: 100%;
}

.medichip-container {
  display: flex;
  margin: auto;
  padding: 150px 0;
  width: 60%;
  gap: 5%;
}

.medichip-container img {
  width: 100%;
}

.medichip-content h5 {
  font-size: 45px;
  font-weight: bold;
}

.medichip-content h2 {
  font-size: 85px;
  font-weight: bold;
  color: black;
}

.medichip-content p {
  font-size: 24px;
  color: black;
}

.what-we-do {
  width: 100%;
  padding: 150px 100px;
  display: flex;
  /* gap: 40px; */
  align-items: center;
}

.what-we-do-content {
  width: 40%;
  margin-right: 100px;
}

.title-bg h2 {
  margin-bottom: 15px;
  font-size: 5rem;
  font-weight: bold;
  line-height: normal;
  color: black;
}

.title-bg h2 span {
  color: #00d3b0;
}

.title-bg p {
  font-size: 2rem;
  color: black;
}

.technology {
  display: flex;
  gap: 20px;
  width: 70%;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.technology-cards {
  width: 45%;
  padding: 50px;
  background-color: #d3b17f;
  border-radius: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.technology-info h6 {
  color: #373737;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 50px;
  height: 150px;
  display: flex;
  align-items: center;
}

.technology-info p {
  color: white;
  font-size: 35px;
}

.biochamber-img {
  width: 100%;
}

.biochamber-img img {
  width: 100%;
}

.biochamber-info {
  width: 70%;
  margin: -90px auto 150px auto;
  padding-top: 50px;
}

.biochamber-info h2 {
  font-size: 85px;
  font-weight: bold;
  line-height: normal;
  color: black;
  margin-bottom: 50px;
}
.biochamber-info h2 span {
  color: #00d3b0;
}

.biochamber-info-content {
  display: flex;
  align-items: center;
}

.info-list, .info-description {
  width: 50%;
}

.info-list ul, .info-description p {
  color: black;
  font-size: 34px;
  padding: 0;
}

.info-list ul li {
  display: flex;
  align-items: center;
}

.info-list ul li img {
  width: 7%;
  margin-right: 3px;
}

.chamber-button {
  text-align: center;
  margin-top: 50px;
}

.chamber-button button {
  font-size: 25px!important;
  font-weight: 600;
}



.testimonial {
  background-color: #373737;
  padding: 100px 0;
}

.testimonial-container {
  width: 70%;
  margin: auto;
}

.testimonial-container h2 {
  font-size: 85px;
  font-weight: bold;
  margin: 80px 0;
  color: white;
}

.testimonial-slider {
  flex: 0 60%;
  display: flex;
  /* gap: 30px; */
  margin: 100px 0;
}

.testimonial-card {
  padding: 50px;
  background-color: #00d3b0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px 0px;
}

.testimonial-card img {
  width: 90%;
  margin: auto;
}
.testimonial-card h4 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 20px;
}

.testimonial-card p {
  font-size: 18px;
  height: 120px;
}

.rating {
  display: flex;
  gap: 5px;
  margin: 15px 0;
}

.rating img {
  width: 15%;
}

.call-to-action {
  text-align: center;
  padding: 100px 0;
}

.cta-container {
  width: 80%;
  margin: auto;
}

.cta-container h1 {
  padding: 50px 0 25px 0;
  margin: 0;
  font-size: 80px;
  color:#00d3b0;
  font-weight: bold;
}

.cta-container h3 {
  padding: 25px 0 50px 0;
  margin: 0;
  font-size: 45px;
  color: #373737;
  font-weight: 600;
}

.cta-button {
  margin: 50px 0;
}

.cta-button button {
  font-size: 28px!important;
  font-weight: 600;
}

/*********************************** Responsive 2325px ************************************/

@media (max-width: 2325px) {
  .title-bg h2 {
    font-size: 4rem;
  }
  .title-bg p {
    font-size: 1.6rem;
  }
}

/*********************************** Responsive 1987px ************************************/
@media (max-width: 1987px) {
  .appointment-info {
    margin: -19% auto 0 auto;
  }
  .appointment-info h3 {
    font-size: 75px;
  }

  .technology-info h6 {
    font-size: 40px;
  }
  .technology-info p {
    font-size: 28px;
  }
  
  .testimonial-card {
    padding: 30px;
  }
  .testimonial-card h4 {
    font-size: 25px;
  }
  .testimonial-card p {
    font-size: 15px;
  }
}

/*********************************** Responsive 1937px ************************************/
@media (max-width: 1937px) {
  .info-list ul {
    font-size: 28px;
  }
}

/*********************************** Responsive 1791px ************************************/
@media (max-width: 1791px) {
  .appointment-info h3 {
    font-size: 65px;
  }
  .service-info {
    padding: 50px;
  }
  .service-info h5 {
    font-size: 32px;
  }
  .working-time-1 h6 {
    font-size: 28px;
  }
  .working-time-1 p {
    font-size: 18px;
  }
  .services-ratings {
    width: 70%;
  }

  .medichip-container {
    width: 70%;
    gap: 8%;
  }
  .medichip-img {
    width: 30%;
  }
  .medichip-content {
    width: 70%;
  }

  .what-we-do-content {
    width: 30%;
  }
  .title-bg h2 {
    font-size: 55px;
  }
  .title-bg p {
    font-size: 18px;
  }

  .biochamber-info {
    width: 80%;
  }
  .info-description p {
    font-size: 28px;
  }
}

/*********************************** Responsive 1489px ************************************/
@media (max-width: 1489px) {
  .title-bg h2 {
    font-size: 45px;
  }
  .service-info-content .buttons button p {
    font-size: 14px;
  }
}

/*********************************** Responsive 1441px ************************************/
@media (max-width: 1441px) {
  /* button {
    font-size: 14px !important;
  } */
  .appointment-info h3 {
    font-size: 45px;
  }
  .service-info {
    padding: 30px 45px;
  }
  .service-info h5 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .working-time-1 h6 {
    font-size: 22px;
  }
  .working-time-1 p {
    font-size: 15px;
  }
  /* .working-time-2 {
    width: 37%;
  } */
  .services-ratings h3 {
    font-size: 55px;
  }

  .medichip-container {
    width: 80%;
    gap: 7%;
  }
  .medichip-img {
    width: 35%;
  }
  .medichip-content {
    width: 65%;
  }
  .medichip-content h5 {
    font-size: 35px;
  }
  .medichip-content h2 {
    font-size: 65px;
  }
  .medichip-content p {
    font-size: 18px;
  }

  .technology-cards {
    padding: 40px;
  }
  .technology-info h6 {
    height: 100px;
    font-size: 30px;
  }
  .technology-info p {
    font-size: 20px;
  }

  .info-list ul {
    font-size: 23px;
  }
  .info-description p {
    font-size: 22px;
  }

  .testimonial-container h2 {
    font-size: 60px;
  }
  .testimonial-card {
    padding: 20px;
  }
  .testimonial-card h4 {
    font-size: 23px;
  }
  .testimonial-card p {
    font-size: 12px;
    height: 100px;
  }
  .rating img {
    width: 15%;
  }

  .cta-container h1 {
    font-size: 60px;
  }
  .cta-container h3 {
    font-size: 35px;
  }
}

/*********************************** Responsive 1273px ************************************/
@media (max-width: 1273px) {
  .title-bg h2 {
    font-size: 35px;
  }
}

/*********************************** Responsive 1119px ************************************/
@media (max-width: 1119px) {
  .what-we-do {
    padding: 40px;
    flex-direction: column;
  }
  .what-we-do-content {
    width: 90%;
    margin: 0 auto 50px auto;
  }
  .title-bg h2 {
    font-size: 48px;
  }
  .title-bg p {
    font-size: 16px;
    color: black;
  }
  .technology {
    width: 90%;
  }

  .testimonial-card h4 {
    font-size: 20px;
  }
  .testimonial-card p {
    font-size: 10px;
    height: 80px;
  }
  .rating {
    margin: 10px 0;
  }
}

/*********************************** Responsive 1025px ************************************/
@media (max-width: 1025px) {
  .appointment-info h3 {
    font-size: 35px;
  }
  .service-info {
    padding: 20px 35px;
  }
  .service-info h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .working-time-1 {
    width: 100%;
  }
  .working-time-1 h6 {
    font-size: 17px;
  }
  .working-time-1 p {
    font-size: 12px;
  }
  .service-info-content .buttons button p {
    font-size: 12px;
  }
  .services-ratings {
    width: 78%;
    padding: 80px 0;
  }
  .services-ratings h3 {
    font-size: 40px;
  }

  .medichip-container {
    display: flex;
    margin: auto;
    padding: 80px 0;
  }

  .biochamber-info {
    margin: -70px auto 100px auto;
  }
  .biochamber-info h2 {
    font-size: 55px;
  }
  .info-list ul, .info-description p {
    font-size: 16px;
  }
  .chamber-button button {
    font-size: 20px !important;
  }
}

/*********************************** Responsive 769px ************************************/
@media (max-width: 769px) {
  .banner-slider-content {
    padding: 0 30px;
  }
  .appointment-info h3 {
    font-size: 25px;
  }
  .service-info {
    padding: 20px;
  }
  .service-info h5 {
    font-size: 18px;
    font-weight: bold;
  }
  .service-info-content {
    width: 100%;
  }
  .working-time-1 {
    text-align: center;
  }
  .working-time-1 p {
    font-size: 12px;
  }
  .appointment-button {
    display: flex;
    justify-content: center;
   }
  .book-button {
    max-width: 250px;
    margin: auto;
  }
  .service-info-content .buttons button p {
    font-size: 10px;
  }

  .services-ratings {
    width: 80%;
  }
  .services-ratings h3 {
    font-size: 32px;
  }
  .services-ratings h6 {
    font-size: 14px;
  }

  .medichip-container {
    padding: 30px 0;
    width: 90%;
  }
  .medichip-content h5 {
    font-size: 22px;
  }
  .medichip-content h2 {
    font-size: 35px;
  }
  .medichip-content p {
    font-size: 12px;
  }

  
  .technology-cards {
    padding: 30px;
  }
  /* .technology-cards img {
    width: 60%;
  } */
  .technology-info h6 {
    height: 50px;
    font-size: 22px;
    margin-bottom: 20px;
  }
  .technology-info p {
    font-size: 15px;
  }

  .biochamber-info {
    width: 80%;
    margin: 30px auto;
    padding-top: 0;
  }
  .biochamber-info h2 {
    font-size: 50px;
  }
  .info-list ul, .info-description p {
    font-size: 12px;
  }
  .chamber-button {
    margin-top: 30px;
  }
  .chamber-button button {
    font-size: 15px !important;
  }

  .testimonial {
    padding: 50px 0;
  }
  .testimonial-container {
    width: 80%;
  }
  .testimonial-container h2 {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .testimonial-card {
    padding: 15px;
  }
  .testimonial-card h4 {
    font-size: 18px;
  }
  .rating {
    gap: 1px;
  }
  .rating img {
    width: 14%;
  }
  .testimonial-card p {
    font-size: 10px;
    height: 80px;
  }

  .call-to-action {
    padding: 80px 0;
  }
  .cta-container h1 {
    padding: 40px 0 15px 0;
    font-size: 38px;
  }
  .cta-container h3 {
    padding: 15px 0 40px 0;
    font-size: 18px;
  }
  .cta-button {
    margin: 20px 0;
  }
  .cta-button button {
    font-size: 20px!important;
  }
}

@media (max-width: 587px) {
  .title-bg h2 {
    font-size: 30px;
  }
  .title-bg p {
    font-size: 12px;
  }
  .technology-cards {
    padding: 10px;
    border-radius: 10px 0px;
  }
  .technology-info h6 {
    height: 30px;
    font-size: 15px;
  }
  .technology-info p {
    font-size: 10px;
  }
  .biochamber-info h2 {
    margin-bottom: 30px;
  }
  .info-list ul, .info-description p {
    font-size: 10px;
  }
  .testimonial-slider {
    flex-wrap: wrap;
  }
}

@media (max-width: 541px) {
  .appointment-info h3 {
    font-size: 18px;
  }
  .service-info h5 {
    font-size: 17px;
  }
  /* .service-info p {
    font-size: 10px;
  } */
  .service-info-content .buttons button p {
    /* font-size: 7px; */
  }
  
  .services-ratings {
    width: 70%;
    padding: 60px 0;
  }
  .services-ratings h3 {
    font-size: 28px;
  }
  .services-ratings h6 {
    font-size: 10px;
  }
}

@media (max-width: 426px) {
  .banner-slider-content {
    padding: 0 20px;
  }
  .appointment-info {
    margin: 0 auto;
  }
  .appointment-info h3 {
    margin-top: 10px;
    font-size: 15px;
    color: black;
  }

  hr.divider {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .services-ratings h3 {
    font-size: 20px;
  }
  .services-ratings h6 {
    font-size: 8px;
  }

  .medichip-container {
    display: block;
    text-align: center;
  }

  .medichip-img {
    width: 100%;
  }
  .medichip-img img {
    width: 40%;
    margin: auto;
  }
  .medichip-content {
    margin-top: 20px;
    padding: 0 40px;
    width: 100%;
  }
  .medichip-content h5 {
    font-size: 15px;
  }
  .medichip-content h2 {
    font-size: 32px;
  }
  .medichip-content p {
    font-size: 10px;
  }

  .what-we-do-content {
    width: 90%;
    margin: 0 auto 30px auto;
  }

  .biochamber-info h2 {
    font-size: 35px;
  }
  .info-list,
  .info-description {
    width: 100%;
  }
  .info-list ul {
    margin-bottom: 20px;
  }

  .what-we-do {
    padding: 20px;
  }
  .technology-cards{
    width: 100%;
    padding: 30px;
    border-radius: 30px 0px;
  }
  .technology-info p {
    font-size: 12px;
  }

  .testimonial-card {
    width: 100%;
    /* margin: 10px 40px; */
  }

  .call-to-action {
    padding: 50px 0;
  }
  .cta-container h1 {
    font-size: 30px;
  }
  .cta-container h3 {
    font-size: 15px;
  }
  .cta-button button {
    font-size: 15px!important;
  }
}

@media (max-width: 376px) {
  .appointment-info h3 {
    font-size: 13px;
  }
  .service-info {
    padding: 10px 20px;
  }
  .service-info h5 {
    font-size: 15px;
  }
  .working-time-1 h6 {
    font-size: 14px;
  }
  /* .service-info p {
    font-size: 8px;
  } */
  .services-ratings {
    width: 80%;
    padding: 35px 0;
  }

  .title-bg h2 {
    font-size: 28px;
  }
  .title-bg p {
    font-size: 10px;
  }

  .technology-cards, .testimonial-card {
    margin: 0;
  }

  .biochamber-info-content {
    flex-wrap: wrap;
  }
  .info-list, .info-description {
    width: 100%;
  }

  .cta-container h1 {
    padding: 15px 0 7.5px 0;
    font-size: 25px;
  }
  .cta-container h3 {
    padding: 7.5px 0 15px 0;
    font-size: 12px;
  }
  .cta-button button {
    font-size: 12px!important;
  }
}