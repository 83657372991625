input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

.booking input[type=date], .booking input[type=date]:focus,
.custom-select-header {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}
.custom-select-container {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.custom-select-header {
  background-color: white;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #495057;
}

.arrow {
  margin-right: 5px;
  font-size: 12px;
}

.custom-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #00d3b0;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  border-radius: 10px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
}

.custom-select-option {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  list-style: none;
}

.custom-select-dropdown::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-select-dropdown {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}