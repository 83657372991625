/* General Styling */

/* Main Content Area */
.main-content {
  width: 100%;
  height: calc(100vh - 115px);
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.main-content::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1024px) {
  .main-content {
    height: calc(100vh - 92px);
  }
}
@media (max-width: 768px) {
  .main-content {
    height: calc(100vh - 76px);
  }
}
@media (max-width: 575px) {
  .main-content {
    height: calc(100vh - 73px);
  }
}


/* Side Bar Styling */
.ps-sidebar-container, .ps-submenu-content {
  background-color: #2e2e2e !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.ps-sidebar-container::-webkit-scrollbar, .ps-submenu-content::-webkit-scrollbar {
  display: none;
}
.ps-submenu-content {
  min-width: 280px !important;
}

.side-bar-container {
  height: 100vh;
  position: fixed;
  z-index: 120;
}

.side-menu-brand-area {
  width: 100%;
  padding: 20px 15px;
  display: flex;
}
.side-menu-logotext {
  width: 100%;
  max-width: 180px;
}
@media (max-width: 767px) {
  .side-menu-logotext {
    max-width: 180px;
  }
}
.side-menu-logo {
  width: 50px;
}

.side-menu-icon {
  font-size: 22px;
  color: #FFF;
}

.side-menu-text {
  margin-top: 20px;
  color: #FFF;
  font-weight: 700;
  font-size: 14px;
}

.section-preset {
  min-height: 100vh;
  padding: 15px 20px;
  margin-bottom: 50px;
}
@media (max-width: 399px) {
  .section-preset {
    padding: 10px;
  }
}

.data-box {
  background-color: #eeecec;
  border-radius: 15px;
  padding: 15px;
}

.selected-package {
  background-color: #cbfcf4;
}




/* Header */
.header {
  padding: 20px 30px;
}
@media (max-width: 769px) {
  .header {
    padding: 15px 30px;
  }
}
@media (max-width: 426px) {
  .header {
    padding: 20px;
  }
}

.header-logo {
  width: 100%;
  max-width: 320px;
}
@media (max-width: 1025px) {
  .header-logo {
    max-width: 220px;
  }
}
@media (max-width: 769px) {
  .header-logo {
    max-width: 150px;
  }
}

.menu-icon {
  font-size: 28px;
  color:#00d3b0;
}

.menu-icon:hover {
  scale: 1.05;
  transition: 0.5s;
}




/* Footer */
.footer-container {
  width: 75%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container h3 {
  width: 40%;
  font-style: italic;
  font-size: 50px;
  font-weight: bold;
}

.socials-icons {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.socials-icons a {
  width: 15%;
  margin-right: 30px;
}

.socials-icons a img {
  width: 100%;
}

@media (max-width: 2218px) {
  .footer-container {
    width: 65%;
  }
  .footer-container h3 {
    font-size: 35px;
  }
}

@media (max-width: 1909px) {
  .footer-container {
    width: 75%;
  }
}

@media (max-width: 1501px) {
  .footer-container {
    width: 80%;
  }
}

@media (max-width: 1441px) {
  .footer-container h3 {
    font-size: 28px;
  }
}

@media (max-width: 1148px) {
  .footer-container h3 {
    font-size: 25px;
  }
}

@media (max-width: 1025px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }
  .footer-container h3 {
    width: 100%;
    margin-bottom: 20px;
  }
  .socials-icons a {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 769px) {
  .footer-container h3 {
    font-size: 16px;
  }
}

@media (max-width: 568px) {
  .socials-icons a {
    width: 35%;
    font-size: 32px;
  }
}

@media (max-width: 532px) {
  .socials-icons a {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 426px) {
  .socials-icons {
    width: 70%;
  }
}

.social-button {
  color: #FFF;
}
.social-button:hover {
  color: #e7e7e7;
}

.footer-icon {
  font-size: 64px;
}

@media (max-width: 767px) {
  .footer-icon {
    font-size: 48px;
  }
}

@media (max-width: 575px) {
  .footer-icon {
    font-size: 36px;
  }
}
