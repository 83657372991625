/* @font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.woff') format('woff'),
         url('../fonts/Montserrat-Bold.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
} */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');

.blue {color:#629cff;}
.yellow {color: #fdefc2;}
.pending {color: #e7a900;}

.text-gray {color: #cbcbcb;}
.profit-green {color:#48a600;}
.error {color: #bb0000;}

body {
    margin:0;
    padding:0;
    /* font-family: 'Montserrat'!important; */
}

h1, h2, h3, h4, h5, h6, p, button {font-family: "Montserrat", sans-serif;}


p {font-size: 16px;}
@media (max-width: 575px) {
    p {font-size: 14px;}
}

.small-font {
    font-size: 14px;
}


.fluid-padding {
    padding-left: 25px;
    padding-right: 25px;
}
@media (min-width: 1200px) {
    .fluid-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (min-width: 1400px) {
    .fluid-padding {
        padding-left: 200px;
        padding-right: 200px;
    }
}
@media (min-width: 1700px) {
    .fluid-padding {
        padding-left: 320px;
        padding-right: 320px;
    }
}

.form-page-padding {
    padding-left: 5%;
    padding-right: 5%;
}
@media (min-width: 767px) {
    .form-page-padding {
        padding-left: 15%;
        padding-right: 15%;
    }
}

button:focus {
    box-shadow: none !important;
}

.main-button {
    font-family: "Montserrat", sans-serif;
    background-color: #00d3b0;
    color: #FFF;
    padding: 10px 20px;
}
@media (max-width: 541px) {
    .main-button {
        padding: 10px;
    }
}

.main-button:hover {
    background-color: #74ead7;
    color: #FFF;
    transition: 0.5s;
}

.setting-button {
    background-color: #00d3b0;
    color: #FFF;
    padding: 5px 10px;
}

.setting-button:hover {
    background-color: #74ead7;
    color: #FFF;
    transition: 0.5s;
}

.secondary-button {
    background-color: #cecece;
}


.desktop-sm {
    display: block;
}
@media (max-width: 575px) {
    .desktop-sm {
        display: none;
    }
}
.mobile-sm {
    display: none;
}
@media (max-width: 575px) {
    .mobile-sm {
        display: block;
    }
}
.desktop-md {
    display: block;
}
@media (max-width: 767px) {
    .desktop-md {
        display: none;
    }
}
.mobile-md {
    display: none;
}
@media (max-width: 767px) {
    .mobile-md {
        display: block;
    }
}
.desktop-lg {
    display: block;
}
@media (max-width: 991px) {
    .desktop-lg {
        display: none;
    }
}
.mobile-lg {
    display: none;
}
@media (max-width: 991px) {
    .mobile-lg {
        display: block;
    }
}


.table-container {
    background-color: #eeecec;
    border-radius: 15px;
    padding: 15px;
  }
  
  .details-table {
    overflow-x: auto;
  }
  
  .borderless th, .borderless td {
    border: none;
  }












@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #ffffff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  text-decoration: inherit;
}
a:hover {

}

body {
  margin: 0;
  /* display: flex; */
  place-items: center;

  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: bold;
  font-family: inherit;
  background-color: #00d3b0;
  cursor: pointer;
}
button:hover {
  /* border-color: #646cff; */
}
button:focus,
button:focus-visible {
  /* outline: 4px auto -webkit-focus-ring-color; */
}

@media (prefers-color-scheme: light) {
  :root {
    /* color: #213547; */
    background-color: #ffffff;
  }
  a:hover {
    /* color: #747bff; */
  }
  button {
    /* background-color: #f9f9f9; */
  }
}

footer {
  background-color: #00d3b0;
  padding: 50px 0;
}
@media (max-width: 769px) {
  footer {
    padding: 30px;
  }
}
