.form-bg {
    background-color: #eeecec;
}


input[type=text], input[type=text]:focus, input[type=password], input[type=password]:focus {
    width: 100%;
    padding: 8px 10px;
    font-size: 16px;
    /* background-color: #F3F5F7; */
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
  }